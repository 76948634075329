<script setup lang="ts">
const userStore = useUserStore();
</script>

<template>
  <div class="profile-avatar">
    <common-drop
      :container="$device.isDesktop ? { top: '44px', right: '0px' } : { top: '44px', left: '0px' }"
    >
      <template #header>
        <div class="profile-avatar-header">
          <common-image :image="userStore.getAvatar"></common-image>
        </div>
      </template>
      <template #container>
        <common-page-header-profile-avatar-container></common-page-header-profile-avatar-container>
      </template>
    </common-drop>
  </div>
</template>

<style scoped lang="scss">
.profile-avatar {
  &-header {
    @apply w-10 h-10 rounded-full overflow-hidden;
  }
}
</style>
