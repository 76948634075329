<script setup lang="ts">
import type { NavigationItem } from '@/interfaces';

const localePath = useLocalePath();
defineProps<{
  item: NavigationItem;
  isApproved: boolean;
}>();
</script>

<template>
  <li>
    <nuxt-link v-if="isApproved && item.path" :to="localePath(item.path!)" active-class="active">
      {{ item.name }}
    </nuxt-link>
    <span v-else>{{ item.name }}</span>
  </li>
</template>

<style scoped lang="scss">
li {
  a,
  span {
    transition: 0.3s color;
    border-color: transparent;
    @apply border border-solid text-separator rounded-[200px] py-1 px-2;
    &.active {
      transition: 0.3s all;
      @apply border-accent text-accent;
    }
    &:hover {
      transition: 0.3s color;
      @apply text-accent;
    }
  }
}
</style>
