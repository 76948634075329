<script setup lang="ts">
  const userStore = useUserStore();
  const { t } = useI18n();
  const localePath = useLocalePath();
</script>

<template>
  <section class="container">
    <header>
      <div class="name">{{ userStore.getName || t('components.common.header.nameless') }}</div>
      <div class="email">{{ userStore.getEmail }}</div>
    </header>
    <div class="separator"></div>
    <main>
      <nuxt-link :to="localePath({ name: Page.PROFILE })">{{ t('components.common.header.profile') }}</nuxt-link>
    </main>
    <div class="separator"></div>
    <main>
      <nuxt-link :to="localePath({ name: Page.HISTORY })">{{ t('components.common.header.historyOfOrders') }}</nuxt-link>
    </main>
    <div class="separator"></div>
    <footer class="cursor-pointer" @click="userStore.logout">
      <span>{{ t('components.common.header.logout') }}</span>
    </footer>
  </section>
</template>

<style scoped lang="scss">
.container {
  @apply w-[236px] rounded-md border border-solid border-gray-4 p-2 bg-white text-sm;
  header {
    @apply py-1.5 px-2;
    .name {
      @apply font-medium;
    }
    .email {
      @apply text-gray-2 text-xs;
    }
  }
  main {
    @apply w-full py-1.5 px-2 rounded-md;
    &:hover {
      transition: 0.3s all;
      @apply bg-gray-5;
    }
  }
  footer {
    @apply w-full py-1.5 px-2 gap-2 rounded-md;
    &:hover {
      transition: 0.3s all;
      @apply bg-gray-5;
    }
  }
  .separator {
    @apply w-full h-[1px] bg-gray-4 my-1;
  }
}
</style>
