<script setup lang="ts">
import socket from '@/socket';
import { useEmitter } from '@/use/emitter';
import { Account, SocketEvent, SocketResponse } from '@/interfaces';

const userStore = useUserStore();
const walletStore = useWalletStore();
const accountStore = useAccountStore();

const accountUpdate = (data: SocketResponse<Account>) => {
  if (data.action === 'update') accountStore.updateAmount(data.data);
};

const walletAction = ({
  data,
  action,
}: {
  data: { address: string; amount: number };
  action: string;
}) => {
  walletStore.create(data);
};

// walletStore.create({ address: 'TLsfZfKW7wpFBa4Ledd3WqGmN6mUdUk1zC', amount: 1 });

const emitter = useEmitter();
onMounted(async () => {
  try {
    await accountStore.loadList();
    /* socket.on(SocketEvent.ACCOUNT, accountUpdate); */
    emitter.on('wallet-action', walletAction);
  } catch {}
});

onUnmounted(() => {
  socket.off(SocketEvent.ACCOUNT);
  emitter.off('wallet-action');
});
</script>

<template>
  <div class="account flex flex-ic">
    <div class="account-container flex flex-ic">
      <common-page-header-profile-account-item
        v-for="item of accountStore.getList"
        :key="item.getId"
        :item="item"
      ></common-page-header-profile-account-item>
    </div>
    <lazy-common-page-header-profile-account-action
      v-if="userStore.isApprovedVerification"
    ></lazy-common-page-header-profile-account-action>
  </div>
</template>

<style scoped lang="scss">
.account {
  @apply border border-solid border-gray-4 rounded-md;
  &-container {
    @apply px-3 py-1.5;
  }
}
</style>
