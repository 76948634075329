<script setup lang="ts">
import src from '@/assets/icons/common/cart.svg';

const cartStore = useCartStore();
const localePath = useLocalePath();

const navigateToCart = async () => {
  await navigateTo(localePath({ name: Page.CART }));
};
</script>

<template>
  <div @click="navigateToCart" class="cart-container">
    <span class="red-count">{{ cartStore.getCartLength }}</span>
    <common-icon :src="src"></common-icon>
  </div>
</template>

<style lang="scss">
.red-count {
  color: var(--ui-color--accent);
  font-size: 14px;
}
.cart-container {
  @apply
    flex items-center gap-1
    px-3 py-1
    border border-solid border-gray-4 rounded-md
    cursor-pointer;
}
</style>
