<script setup lang="ts">
  import locales from '@/i18n/locales';

  const { locale } = useI18n()

  const currentlocale = computed(() => {
    return locales.find(item => item.code === locale.value)?.name ?? 'Rus'
  })

  const localesRef = ref(locales)

  const switchLocalePath = useSwitchLocalePath()

  withDefaults(
    defineProps<{ buttonMod?: 'btn-small'|'btn-accent'|'btn-white' }>(),
    {
      buttonMod: 'btn-small',
    },
  )
</script>

<template>
  <common-drop :container="{ top: '44px', left: '0px', right: '0px' }">
    <template #header>
      <common-button
        :text="currentlocale"
        :class-mods="buttonMod"
      ></common-button>
    </template>

    <template #container="{ toggle }">
      <ul class="locale-container">
        <li
          class="locale-item"
          v-for="locale of localesRef"
          :key="locale.code"
          @click="() => { toggle() }"
        >
          <nuxt-link
            class="locale-link full-pseudo"
            :to="switchLocalePath(locale.code)"
          >
            {{ locale.name }}
          </nuxt-link>
        </li>
      </ul>
    </template>
  </common-drop>
</template>

<style scoped lang="scss">
  .locale {
    &-container {
      @apply border border-solid border-gray-4 rounded-md max-h-60 overflow-auto;
    }

    &-item {
      @apply flex items-center justify-center gap-3 p-2 cursor-pointer relative;

      &:not(:last-child) {
        @apply border-b border-solid border-b-gray-4;
      }
    }

    &-link {
      @apply font-medium transition-colors duration-300 text-black;

      @media(hover:hover) {
        &:hover {
          @apply text-accent-hover;
        }
      }

      &.full-pseudo::after {
        @apply content-[''] absolute top-0 right-0 bottom-0 left-0;
      }

      &.router-link-active.router-link-exact-active {
        @apply text-accent;
      }
    }
  }
</style>